const baseUrl = 'https://zh-api.hnzhonghe.cn/api/v1/';  //和代理相对应的请求地址
//const baseUrl = 'https://test-api.hnzhonghe.cn/api/v1/'; //测试和代理相对应的请求地址
/* *api接口的统一管理* */
export default {
  //登录
  login: baseUrl + 'auth/login',
  //忘记密码
  forgetPwd: baseUrl + 'auth/resetpwd',
  //未登录前发送手机验证码
  sendCodeBefor: baseUrl + 'auth/sendCode',
  //获取用户信息
  getUserInfo: baseUrl + 'user/info',
  //修改用户信息
  getUpdateInfo: baseUrl + 'user/update',
  //获取广告轮播图
  getFlash: baseUrl + 'ad/flash',
  //获取培训对象
  getTrainObject: baseUrl + 'cate/trainobject',
  //获取课程类型
  getCurriculumCate: baseUrl + 'cate/curriculumcate',
  //获取培训方式
  getTrainType: baseUrl + 'cate/traintype',
  //获取课程列表
  getCurriculumLists: baseUrl + 'curriculum/lists',
  //课程详情
  getCurriculumDetail: baseUrl + 'curriculum/detail',
  //课程报名
  getCurriculumBaoming: baseUrl + 'order/create',
  //课程订单详情
  getOrderDetail: baseUrl + 'order/detail',
  //未登录 获取手机验证码
  sendCodeAfter: baseUrl + 'auth/sendCode',
  //学历分类
  getEducationCate: baseUrl + 'cate/educationCate',
  //上传接口
  uploadImages: baseUrl + 'uploadfile/uploadImages',
  //检测注册的基本信息
  checkRegisterBase: baseUrl + 'auth/checkBase',
  //注册
  toRegister: baseUrl + 'auth/register',
  //获取用户系统消息列表
  getMessageUser: baseUrl + 'messageuser/lists',
  //消息设置为已读
  setMessageUserRead: baseUrl + 'messageuser/setRead',
  //消息全部清空
  delAllMessageUser: baseUrl + 'messageuser/del',
  //订单列表
  getOrder: baseUrl + 'order/index',
  //取消报名/订单
  cencalBaoming: baseUrl + 'order/cancel',
  //本地视频
  getVideoLists: baseUrl + 'video/lists',
  //学习记录
  videoStudy: baseUrl + 'video/study',

  //获取开票类型
  // getKaiPiaoType: baseUrl + 'cate/invoice',
  //获取用户开票信息
  getUserInvoiceList: baseUrl + 'user/invoicelist',
  //添加发票
  addUserInvoice: baseUrl + 'user/addinvoice',
  // 获取用户微信支付地址
  getScannedCodePay: baseUrl + 'pay/scannedCodePay',
  // 获取全部课程
  getTrainLists: baseUrl + 'train/lists',
  // 发票列表
  getOrderinvoiceLists: baseUrl + 'orderinvoice/index',
  // 发票详情
  getOrderinvoiceDetail: baseUrl + 'orderinvoice/detail',
  // 定时循环确认支付
  getPayIssuccess: baseUrl + 'pay/issuccess',
  // 课程修改课程订单状态
  // getFinishStudy: baseUrl + 'third/curriculum/finishStudy',
  //进入课程学习
  getTrainTostudy: baseUrl + 'train/tostudy',
  //配置信息
  getWebsiteInfo: baseUrl + 'website/getInfo',
  //发证记录
  getCertificateList: baseUrl + 'certificate/lists',
  //补考记录
  getExamLogList: baseUrl + 'train/examLog',
  //常见问题列表
  getWhatList: baseUrl + 'commonproblem/lists',
  //常见问题详情
  getWhatInfo: baseUrl + 'commonproblem/detail',
  // ***********
  // ============企业=====================================================
  // ***********
  //选择报名人
  getUserLists: baseUrl + 'qiye/users',
  //立即报名
   enterpriseBaoming: baseUrl + 'qiye/baoming',
   //订单详情报名人数列表
   getOrderUserLists: baseUrl + 'qiye/orderUser',
   //获取企业订单详情
   getQyOrderDetail: baseUrl + 'qiye/orderDetail',
   //企业订单支付二维码
   getQyScannedCodePay: baseUrl + 'pay/qyScannedCodePay',
   // 定时循环确认支付
   getPayQyIssuccess: baseUrl + 'pay/qyIssuccess',
   //获取课程管理
   getQiyeCourse: baseUrl + 'qiye/course',
   //企业课程报名人数列表/未缴费人数列表/已缴费人数列表
   getBaomingUser: baseUrl + 'qiye/bmUser',
   //取消报名
   qiyeCancelBm: baseUrl + 'qiye/cancelBm',
  //批量报名
  enterpriseCreateOrder: baseUrl + 'qiye/createOrder',
  //修改员工密码
  savePwd: baseUrl + 'qiye/savePwd',
  //企业类型分类
  getEnterprisetype: baseUrl + 'cate/enterprisetype',
  
  // 发证记录
  getCertificate: baseUrl + 'qiye/certificate',
  //判断是否存在招生员
  getHasRecruit: baseUrl + 'cate/hasRecruit',
  //判断是否存在企业
  getHasEnterprise: baseUrl + 'cate/hasEnterprise',
  //获取资质列表
  getQualifications: baseUrl + 'qualifications/lists',
  //添加资质
  addQualifications: baseUrl + 'qualifications/add',
  //编辑资质
  editQualifications: baseUrl + 'qualifications/edit',
  //删除资质
  delQualifications: baseUrl + 'qualifications/del',
  //获取员工证书列表
  getEnterprisePersonCer: baseUrl + 'qiyepersoncer/lists',
  addEnterprisePersonCer: baseUrl + 'qiyepersoncer/add',
  editEnterprisePersonCer: baseUrl + 'qiyepersoncer/edit',
  delEnterprisePersonCer: baseUrl + 'qiyepersoncer/del',
  daochuEnterprisePersonCer: baseUrl + 'qiyepersoncer/daochu',
  //获取企业员工列表
  getEnterpriseYuangong: baseUrl + 'user/getEnterpriseYuangong',
  //获取专业
  getTrainMajor: baseUrl + 'cate/trainmajor',
  //获取企业证书类型
  getCertMajor: baseUrl + 'cate/certmajor',
  //企业免密登录
  freeLogin: baseUrl + 'auth/freeLogin',
}
