import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const store = new Vuex.Store({
	//state是自定义的一些变量，需要用来保存数据
	state:{
		// 就是公共的数据，所有的组件都可以直接使用
		isLogin:0,//用户是否登录 0-表示用户未登录,1-表示用户已登录
    loading: false//loading
	},
	 
	//mutations是用来触发事件，相当于方法，用户需要通过触发这个方法，借此来保存数据，参数的话，第二个参数就是用户传入的值，然后在方法中赋值给state中的变量
	mutations:{
		// 改变用户登录状态
		changeLoginState(state,data){
			state.isLogin = data;
		},
    showLoading(state){
        state.loading = true    
    },
    hideLoading (state) {
        state.loading = false
    }
	}
})
export default store