//一、引入
//main.js是入口文件，主要作用是初始化vue实例，并引入所需插件
//若只给出vue这样的包名，则会自动到node_modules中加载；若给出相对路径及文件前缀，则到指定位置寻找。
import Vue from 'vue';//实际路径："../node_modules/vue/dist/vue.js";
import App from './app.vue';
import QRCode from "qrcode";
import router from './router';
//导入store
import store from './store'
//引入element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';//element-ui的css
//全局引入请求方法
import request from '../utils/request'
import myMessage from '../utils/message';


//二、安装使用
//安装使用插件,插件通常用来为 Vue 添加全局功能
Vue.use(ElementUI);
Vue.use(QRCode);
/* ElementUI.Message({
	type: 'success',
	message: '公共的弹窗',
	showClose: false,//默认的message是不可以手动关闭的，如果需要手动关闭，设置showClose为true，一般与duration搭配使用
	duration: 3000,//显示时间, 毫秒。设为 0 则不会自动关闭，默认3000ms
	center: true,//是否居中
	dangerouslyUseHtmlString: false,//将此属性设置为true，message会被当成html片段处理
}) */

//三、直接挂载注册
//如果不想通过编写组件然后Vue.use的方式引入全局方法，也可以直接在main.js通过Vue.prototype注册全局方法/变量/组件
//挂载到vue原型上
Vue.prototype.$request = request;
Vue.prototype.$myMessage = myMessage;
Vue.prototype.$QRCode = QRCode;
//js文件调用
//myMessage.warning('请登录');

//作用-阻止vue启动生产消息
Vue.config.productionTip = false;

new Vue({
  router,
  store, // 2. 注入Vue实例
  render: (h) => h(App),
}).$mount('#app');
