import intefUrl from "./interface"
import $ from './http'
import {
  setToken
} from './http'
/* *接口数据的统一调用* */
export default {
  /***    获取通用内容    ***/
  // 登录
  login(params) {
    return $.post(intefUrl.login, params);
  },
  //忘记密码
  forgetPwd(params) {
    return $.post(intefUrl.forgetPwd, params);
  },
  //未登录前发送手机验证码
  sendCodeBefor(params) {
    return $.post(intefUrl.sendCodeBefor, params);
  },
  getUserInfo(params) {
    return $.post(intefUrl.getUserInfo, params);
  },
  //获取广告轮播图
  getFlash(params) {
    return $.post(intefUrl.getFlash, params);
  },
  //获取培训对象
  getTrainObject(params) {
    return $.post(intefUrl.getTrainObject, params);
  },
  //获取课程类型
  getCurriculumCate(params) {
    return $.post(intefUrl.getCurriculumCate, params);
  },
  //获取培训方式
  getTrainType(params) {
    return $.post(intefUrl.getTrainType, params);
  },
  //获取课程列表
  getCurriculumLists(params) {
    return $.post(intefUrl.getCurriculumLists, params);
  },
  //课程报名
  getCurriculumBaoming(params) {
    return $.post(intefUrl.getCurriculumBaoming, params);
  },
  //课程详情
  getCurriculumDetail(params) {
    return $.post(intefUrl.getCurriculumDetail, params);
  },
  //课程订单详情
  getOrderDetail(params) {
    return $.post(intefUrl.getOrderDetail, params);
  },
  //未登录 获取手机验证码
  sendCodeAfter(params) {
    return $.post(intefUrl.sendCodeAfter, params);
  },
  //学历分类
  getEducationCate(params) {
    return $.post(intefUrl.getEducationCate, params);
  },
  //上传接口
  uploadImages(params) {
    return $.post(intefUrl.uploadImages, params);
  },
  //检测注册基本信息
  checkRegisterBase(params) {
    return $.post(intefUrl.checkRegisterBase, params);
  },
  //员工去注册
  toRegister(params) {
    return $.post(intefUrl.toRegister, params);
  },
  //获取用户系统消息列表
  getMessageUser(params) {
    return $.post(intefUrl.getMessageUser, params);
  },
  //消息设置为已读
  setMessageUserRead(params) {
    return $.post(intefUrl.setMessageUserRead, params);
  },
  //消息全部清空
  delAllMessageUser(params) {
    return $.post(intefUrl.delAllMessageUser, params);
  },

  //订单列表
  getOrder(params) {
    return $.post(intefUrl.getOrder, params);
  },
  //取消报名/订单
  cencalBaoming(params) {
    return $.post(intefUrl.cencalBaoming, params);
  },
  //本地视频
  getVideoLists(params) {
    return $.post(intefUrl.getVideoLists, params);
  },
	//本地视频
	videoStudy(params) {
	  return $.post(intefUrl.videoStudy, params);
	},

  //获取开票类型
  // getKaiPiaoType(params) {
  //   return $.post(intefUrl.getKaiPiaoType, params);
  // },
  //获取用户开票信息
  getUserInvoiceList(params) {
    return $.post(intefUrl.getUserInvoiceList, params);
  },
  //添加发票
  addUserInvoice(params) {
    return $.post(intefUrl.addUserInvoice, params);
  },
  // 获取微信支付地址
  getScannedCodePay(params) {
    return $.post(intefUrl.getScannedCodePay, params);
  },
  // 获取全部课程
  getTrainLists(params) {
    return $.post(intefUrl.getTrainLists, params);
  },
  // 获取发票列表
  getOrderinvoiceLists(params) {
    return $.post(intefUrl.getOrderinvoiceLists, params);
  },
  // 获取发票详情
  getOrderinvoiceDetail(params) {
    return $.post(intefUrl.getOrderinvoiceDetail, params);
  },
  // 定时循环确认支付
  getPayIssuccess(params) {
    return $.post(intefUrl.getPayIssuccess, params);
  },
  // 进入课程学习
  getTrainTostudy(params) {
    return $.post(intefUrl.getTrainTostudy, params);
  },
  // 配置信息
  getWebsiteInfo(params) {
    return $.post(intefUrl.getWebsiteInfo, params);
  },
  // 发证信息
  getCertificateList(params) {
    return $.post(intefUrl.getCertificateList, params);
  },
  // 修改个人信息
  getUpdateInfo(params) {
    return $.post(intefUrl.getUpdateInfo, params);
  },
  // 补考记录
  getExamLogList(params) {
    return $.post(intefUrl.getExamLogList, params);
  },
  // 常见问题列表
  getWhatList(params) {
    return $.post(intefUrl.getWhatList, params);
  },
  // 常见问题详情
  getWhatInfo(params) {
    return $.post(intefUrl.getWhatInfo, params);
  },
  // 企业类型分类
  getEnterprisetype(params) {
    return $.post(intefUrl.getEnterprisetype, params);
  },
	
	/* 企业开始 */
	//获取课程列表
	getUserLists(params) {
	  return $.post(intefUrl.getUserLists, params);
	},
	//立即报名
	enterpriseBaoming(params){
		return $.post(intefUrl.enterpriseBaoming, params);
	},
	//订单详情报名人数列表
	getOrderUserLists(params){
		return $.post(intefUrl.getOrderUserLists, params);
	},
	//企业订单详情
	getQyOrderDetail(params){
		return $.post(intefUrl.getQyOrderDetail, params);
	},
	//企业订单支付二维码
	getQyScannedCodePay(params){
		return $.post(intefUrl.getQyScannedCodePay, params);
	},
	// 定时循环确认支付
	getPayQyIssuccess(params) {
	  return $.post(intefUrl.getPayQyIssuccess, params);
	},
	//获取课程管理
	getQiyeCourse(params) {
	  return $.post(intefUrl.getQiyeCourse, params);
	},
	//企业课程报名人数列表/未缴费人数列表/已缴费人数列表
	getBaomingUser(params) {
	  return $.post(intefUrl.getBaomingUser, params);
	},
	//取消报名
	qiyeCancelBm(params) {
	  return $.post(intefUrl.qiyeCancelBm, params);
	},
	//批量报名
	enterpriseCreateOrder(params){
		return $.post(intefUrl.enterpriseCreateOrder, params);
	},
	//修改学员密码
	savePwd(params){
		return $.post(intefUrl.savePwd, params);
	},
	//判断是否存在招生员
	getHasRecruit(params){
		return $.post(intefUrl.getHasRecruit, params);
	},
	//判断企业是否存在
	getHasEnterprise(params){
		return $.post(intefUrl.getHasEnterprise, params);
	},
	//获取资质
	getQualifications(params){
		return $.post(intefUrl.getQualifications, params);
	},
	//添加资质
	addQualifications(params){
		return $.post(intefUrl.addQualifications, params);
	},
	//编辑资质
	editQualifications(params){
		return $.post(intefUrl.editQualifications, params);
	},
	//删除资质
	delQualifications(params){
		return $.post(intefUrl.delQualifications, params);
	},
	//获取员工证书列表
	getEnterprisePersonCer(params){
		return $.post(intefUrl.getEnterprisePersonCer, params);
	},
	//添加员工证书
	addEnterprisePersonCer(params){
		return $.post(intefUrl.addEnterprisePersonCer, params);
	},
	//编辑员工证书
	editEnterprisePersonCer(params){
		return $.post(intefUrl.editEnterprisePersonCer, params);
	},
	//删除员工证书
	delEnterprisePersonCer(params){
		return $.post(intefUrl.delEnterprisePersonCer, params);
	},
	//导出员工证书
	daochuEnterprisePersonCer(params){
		return $.exportFileRequest(intefUrl.daochuEnterprisePersonCer, params);
	},
	//获取企业员工列表
	getEnterpriseYuangong(params){
		return $.post(intefUrl.getEnterpriseYuangong, params);
	},
	getTrainMajor(params){
		return $.post(intefUrl.getTrainMajor, params);
	},
	getCertMajor(params){
		return $.post(intefUrl.getCertMajor, params);
	},
	freeLogin(params){
		return $.post(intefUrl.freeLogin, params);
	},
}
