import Vue from 'vue';
import VueRouter from 'vue-router';


Vue.use(VueRouter);

const routes = [
	{
	   path: '/',
	   redirect: '/user/accountinfo',
	},
	{
		path: '/user/index',
		name: 'userIndex',
		component: () => import("../pages/layout/index.vue"),
		children: [
			{
				path: '/user/accountinfo',
				name: 'userAccountinfo',
				component: () => import("../pages/user/accountinfo/index.vue"),
				meta: {
					activeMenu: '/user/accountinfo'
				}
			},
			{
				path: '/user/messageuser/index',
				name: 'userMessageuser',
				component: () => import("../pages/user/messageuser/index.vue"),
			},
			{
				path: '/user/what/index',
				name: 'userWhat',
				component: () => import("../pages/user/what/index.vue"),
			},
			{
				path: '/user/curriculum/index',
				name: 'userCurriculum',
				component: () => import("../pages/user/curriculum/index.vue"),
				meta: {
					activeMenu: '/user/curriculum/index'
				},
			},
			{
				path: '/company/applicant',
				name: 'companyApplicant',
				component: () => import("../pages/company/applicant.vue"),
				meta: {
					activeMenu: '/user/curriculum/index'
				}
			},
			{
				path: '/company/order/detail',
				name: 'companyOrderDetail',
				component: () => import("../pages/company/order/detail.vue"),
				meta: {
					activeMenu: '/user/curriculum/index'
				}
			},
			{
				path: '/company/curriculum/course',
				name: 'companyCurriculumCourse',
				component: () => import("../pages/company/curriculum/course.vue"),
				meta: {
					activeMenu: '/user/curriculum/index'
				}
			},
			{
				path: '/company/curriculum/course_num',
				name: 'companyCurriculumCourseNum',
				component: () => import("../pages/company/curriculum/course_num.vue"),
				meta: {
					activeMenu: '/user/curriculum/index'
				}
			},
			{
				path: '/company/curriculum/course_paynum',
				name: 'companyCurriculumCoursePayNum',
				component: () => import("../pages/company/curriculum/course_paynum.vue"),
				meta: {
					activeMenu: '/user/curriculum/index'
				}
			},
			{
				path: '/company/curriculum/course_notpay',
				name: 'companyCurriculumCourseNotPay',
				component: () => import("../pages/company/curriculum/course_notpay.vue"),
				meta: {
					activeMenu: '/user/curriculum/index'
				}
			},
			{
				path: '/company/staff/index',
				name: 'companyCurriculumCourseNotPay',
				component: () => import("../pages/company/staff/index"),
				meta: {
					activeMenu: '/user/staff/index'
				}
			},
			{
				path: '/company/qualifications/index',
				name: 'companyQualificationsIndex',
				component: () => import("../pages/company/qualifications/index"),
				meta: {
					activeMenu: '/user/qualifications/index'
				}
			},
			{
				path: '/company/qiyeperson/index',
				name: 'companyQiyepersonIndex',
				component: () => import("../pages/company/qiyeperson/index"),
				meta: {
					activeMenu: '/user/qiyeperson/index'
				}
			},
			{
				path: '/company/safetycer/index',
				name: 'companySafetycerIndex',
				component: () => import("../pages/company/safetycer/index"),
				meta: {
					activeMenu: '/user/safetycer/index'
				}
			},
			{
				path: '/user/curriculum/detail',
				name: 'userCurriculumDetail',
				component: () => import("../pages/user/curriculum/detail.vue"),
				meta: {
					activeMenu: '/user/curriculum/index'
				}
			},
			{
				path: '/user/order/index',
				name: 'userOrder',
				component: () => import("../pages/user/order/index.vue"),
				meta: {
					activeMenu: '/user/order/index'
				}
			},
			{
				path: '/user/order/detail',
				name: 'userOrderDetail',
				component: () => import("../pages/user/order/detail.vue"),
				meta: {
					activeMenu: '/user/order/index'
				}
			},
			{
				path: '/user/invoice/add',
				name: 'userInvoiceAdd',
				component: () => import("../pages/user/invoice/add.vue"),
			},
			{
				path: '/user/invoice/info',
				name: 'userInvoiceInfo',
				component: () => import("../pages/user/invoice/info.vue"),
			},
			{
				path: '/user/invoice/details',
				name: 'userInvoiceDetail',
				component: () => import("../pages/user/invoice/details.vue"),
			},
			{
				path: '/user/training/list',
				name: 'userTrainingList',
				component: () => import("../pages/user/training/list.vue"),
			},
			{
				path: '/user/training/records',
				name: 'userTrainingRecords',
				component: () => import("../pages/user/training/records.vue"),
			},
			{
				path: '/user/training/detail',
				name: 'userTrainingRecords',
				component: () => import("../pages/user/training/detail.vue"),
			},
			{
				path: '/user/order/paymentSucceeded',
				name: 'paymentSucceeded',
				component: () => import("../pages/user/order/paymentSucceeded.vue"),
			},
			{
				path: '/user/index/info',
				name: 'userIndexInfo',
				component: () => import("../pages/user/index/info.vue"),
			},
			{
				path: '/user/index/index',
				name: 'userIndexIndex',
				component: () => import("../pages/user/index/index.vue"),
			},
			{
				path: '/user/exam/list',
				name: 'makeExam',
				component: () => import("../pages/user/exam/list.vue"),
			},
			{
				path: '/customer/index',
				name: 'customerIndex',
				component: () => import("../pages/customer/index.vue"),
			},
		],
	},
  {
  	path: '/user/training/video',
  	name: 'userTrainingVideo',
  	component: () => import("../pages/user/training/video.vue"),
  },
	{
		path: '/login',
		name: 'login',
		component: () => import("../pages/auth/login.vue"),
	},
	{
		path: '/forgotPassword',
		name: 'forgotPassword',
		component: () => import("../pages/auth/forgotPassword.vue"),
	},
	{
		path: '/register',
		name: 'register',
		component: () => import("../pages/auth/register.vue"),
	},


];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

// 全局路由守卫
router.beforeEach((to, from, next) => {
  //console.log(to);
  //console.log(from);
  //console.log(next);
  //console.log(sessionStorage.getItem('isLogin'));
  // to: Route: 即将要进入的目标 路由对象
  // from: Route: 当前导航正要离开的路由
  // next: Function: 一定要调用该方法来 resolve 这个钩子。执行效果依赖 next 方法的调用参数。

  //不需要验证登录的路由
  const nextRoute = ['login', 'forgotPassword', 'register'];
  let isLogin = sessionStorage.getItem('isLogin');  // 登录状态
  // 未登录状态；当路由到nextRoute指定页时，跳转至login
  if (nextRoute.indexOf(to.name) < 0) {
	if (!isLogin) {
	  router.push({ name: 'login' })
	}
  }

  // 已登录状态；当路由到login时，跳转至index
  if (to.name === 'login') {
    if (isLogin) {
      router.replace('/')
    }
  }

  next();
});

export default router;
