// vue请求封装
// 在http.js中引入axios
import axios from "axios";
import QS from "qs";
import ElementUI from 'element-ui';
import router from '@/router';
// 设置超时时间，毫秒单位
axios.defaults.timeout = 10000;
// 设置默认post的请求头
axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";
//设置请求拦截器，添加token请求
axios.interceptors.request.use(config=>{
	config.headers.token = sessionStorage.getItem('token');
	//请求拦截器编写完成之后，一定要返回config
	return config;
})

// 添加token 在登录成功后进行设置
export function setToken(token) {
    axios.defaults.headers.common["token"] = token;
}

export default {
    /**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
    get(url, params) {
        return new Promise((resolve, reject) => {
            axios.get(url, {
                    params: params,
                }, {
                    async: false,
					responseType: 'blob' //指定返回数据的格式为blob
                })
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    /**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} data [请求时携带的参数]
 */
    post(url, data, contentType) {
        // data = QS.stringify(data)  //post需要序列化
        return new Promise((resolve, reject) => {
            axios
                .post(url, data, {
                    // headers: {
                    //     "Content-Type": contentType ? contentType : "application/json",
                    // },
                    headers: {      //请求头
                        "Accept": "*/*",
                        "Content-Type": contentType ? contentType : "application/json",
                        //"token": ""  //这是获取的token
                    },
                    async: false
                })
                .then(res => {
                    if (res.data.code == 200) {
                        resolve(res.data);
                    } else {
						if(res.data.code == 10001){//需要登录
							ElementUI.Message({
								type: 'error',
								message: res.data.msg,
								showClose: false,//默认的message是不可以手动关闭的，如果需要手动关闭，设置showClose为true，一般与duration搭配使用
								duration: 3000,//显示时间, 毫秒。设为 0 则不会自动关闭，默认3000ms
								center: true,//是否居中
								dangerouslyUseHtmlString: false,//将此属性设置为true，message会被当成html片段处理
							})
              this.$router.replace('/login')
							resolve(res.data);
						}else{
							ElementUI.Message({
								type: 'error',
								message: res.data.msg,
								showClose: false,//默认的message是不可以手动关闭的，如果需要手动关闭，设置showClose为true，一般与duration搭配使用
								duration: 3000,//显示时间, 毫秒。设为 0 则不会自动关闭，默认3000ms
								center: true,//是否居中
								dangerouslyUseHtmlString: false,//将此属性设置为true，message会被当成html片段处理
							})
							resolve(res.data);
						}
						return false;
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
	exportFileRequest(url, params){
		return axios({
			method: 'get',
			url: url,
			params: params,
			responseType: 'blob',
		});
	}
}
